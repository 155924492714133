import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import dsm from "../../utils/dsm"
import { device, visuallyHidden } from "../../utils/mixins"

var Button = styled(Link)`
  background: ${dsm.color.brand.copper.base};
  color: black;
  text-decoration: none;
  border-radius: 1.25rem;
  font-size: 1.25rem;
  padding: 0.5rem;
  ${dsm.fonts.primary};
  display: block;
  font-weight: 750;
  z-index: 1;
  box-shadow: 0.1rem 0.25rem 0.75rem rgba(0, 0, 0, 0.75);
  transition: background 0.35s ease-out;

  :hover {
    background: #debc99;
  }
`

var ImgButton = styled(Button)`
  font-size: 1.25rem;
  text-align: center;
  width: 12rem;
  position: absolute;
  bottom: 1rem;
  right: 0.5rem;
  box-shadow: 0.1rem 0.1rem 1rem ${dsm.color.brand.copper.dark};

  ${device.mobileM} {
    padding: 0.75rem 0.65rem;
    font-size: 1.4rem;
  }
`

export function ImageButton({ children, to }) {
  return <ImgButton to={to}>{children}</ImgButton>
}

var ImageButtonIndex = styled(ImgButton)`
  ${device.tablet} {
    display: none;
    height: 20rem;
  }
`

export function ImageButtonHome({ children, to }) {
  return <ImageButtonIndex to={to}>{children}</ImageButtonIndex>
}

var SectionBtn = styled(Button)`
  padding: 0.65rem;
  font-size: 1.25rem;
  padding-left: 1.25rem;
  margin: 2rem 0.5rem;

  ${device.mobileM} {
    padding: 0.75rem 0 0.75rem 1.25rem;
    font-size: 1.5rem;
  }

  ${device.tablet} {
    width: 49%;
  }

  ${device.laptop} {
    padding: 0.65rem 0 0.65rem 1.25rem;
  }
`

export function SectionButton({ children, to }) {
  return <SectionBtn to={to}>{children}</SectionBtn>
}

var PageSectionBtn = styled(SectionBtn)`
  font-size: unset;
  font-size: 1.2rem;
  margin: 1.75rem 0;
  padding: unset;
  padding: 0.65rem;
  padding-left: 1.25rem;

  ${device.tablet} {
    font-size: unset;
    font-size: 1.4rem;
  }
`

export function PageSectionButton({ children, to }) {
  return <PageSectionBtn to={to}>{children}</PageSectionBtn>
}

var LinkedInBtn = styled.a`
  display: block;
  margin: 1.75rem 0;
  padding: 0.65rem;
  font-size: 1.2rem;
  padding-left: 1.25rem;
  text-decoration: none;
  border-radius: 1.25rem;
  font-size: 1.25rem;
  ${dsm.fonts.primary};
  background: #4177c9;
  color: white;
  display: block;
  font-weight: 700;
  z-index: 1;
  box-shadow: 0.1rem 0.25rem 0.75rem rgba(0, 0, 0, 0.75);
  transition: background 0.35s ease-out;

  :hover {
    background: #2155a5;
  }

  ${device.mobileM} {
    padding: 0.75rem 0 0.75rem 1.25rem;
    font-size: 1.5rem;
  }

  ${device.tablet} {
    font-size: 1.4rem;
    width: 49%;
  }

  ${device.laptop} {
    padding: 0.65rem;
    padding-left: 1.25rem;
  }
`

export function LinkedInButton({ children, href }) {
  return <LinkedInBtn href={href}>{children}</LinkedInBtn>
}

var PgImgButton = styled(ImgButton)`
  ${device.tablet} {
    right: unset;
    left: 0.5rem;
    bottom: 1rem;
    width: 48%;
    padding: 0.65rem;
    text-align: left;
    padding-left: 1.25rem;
    font-size: 1.5rem;
    border-radius: 1.5rem;
  }
`

export function PageImageButton({ children, to }) {
  return <PgImgButton to={to}>{children}</PgImgButton>
}

var FormCont = styled.form`
  ${device.tablet} {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  label {
    ${visuallyHidden}
  }

  input {
    width: 100%;
    height: 2.75rem;
    font-size: 1.25rem;
    margin-bottom: 1.15rem;
    border-radius: 0.25rem;
    border: 0.05rem solid #979797;
    background: #eae7e7;
    padding-left: 0.75rem;
  }

  textarea {
    width: 100%;
    font-size: 1.25rem;
    margin-bottom: 1.15rem;
    border-radius: 0.25rem;
    border: 0.05rem solid #979797;
    background: #eae7e7;
    padding: 0.75rem;
  }
`

export function FormContainer({ children }) {
  return <FormCont>{children}</FormCont>
}
