import React from "react"
import { navigate, Link } from "gatsby"
import styled from "@emotion/styled"
import I2Logo from "./I2Logo"
import * as mixins from "../../utils/mixins"

var MobileNavCheckboxLabel = styled.label`
  ${mixins.visuallyHidden};
`

var MobileNavCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  ${mixins.device.tablet} {
    display: none;
  }
`

var MobileHeaderCont = styled.header`
  height: 4.75rem;
  display: block;
  position: fixed;
  z-index: 9999;
  width: 100%;
  overflow-y: hidden;
  user-select: none;
  background: rgba(251, 249, 248, 0.9);
  transition: background 0.7s cubic-bezier(0, 0, 0, 1) 0.2s,
    height 0.7s cubic-bezier(0.52, 0.16, 0.24, 1);

    ${mixins.device.mobileL} {
      height: 5rem;
    }

    ${mixins.device.tablet} {
      display: none;
    }

  ${MobileNavCheckbox}:checked + &,
  ${MobileNavCheckbox}:target + & {
    height: 100vh;
    background: rgba(251, 249, 248, 1);
    transition: all 0.7s cubic-bezier(0, 0, 0, 1);
  }
`

var HeaderNavBarComponents = styled.div`
  height: 4.75rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.3rem calc((100vw - 1200px) / 2 + 1rem);

  transition: height 0.1s;

  ${mixins.device.mobileL} {
    height: 5rem;
  }
`

var LogoContainer = styled(Link)`
  display: flex;
  height: 100%;
  width: 70%;
  margin-left: 0.35rem;
  z-index: 99;
`

var Logo = styled(I2Logo)`
  width: 75%;
  height: auto;
`

var BreadCont = styled.label`
  display: block;
  width: 25%;
  height: 100%;
  position: relative;
  z-index: 3;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${mixins.device.mobileM} {
    width: 22%;
  }

  ${mixins.device.mobileL} {
    width: 19%;
  }

  ${mixins.device.mobileX} {
    width: 16%;
  }
`

var MenuBread = styled.span`
  transition: transform 0.7s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  transform: none;

  position: absolute;
  display: block;
  top: 15%;
  width: 74%;
  left: 19%;
  height: 70%;
  z-index: 4;
`

var BreadTop = styled(MenuBread)`
  ${MobileNavCheckbox}:checked
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    &,
  ${MobileNavCheckbox}:target
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    & {
    transform: rotate(-315deg);
    transition: transform 0.7s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  }
`

var BreadBottom = styled(MenuBread)`
  ${MobileNavCheckbox}:checked
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    &,
  ${MobileNavCheckbox}:target
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    & {
    transform: rotate(-45deg);
    transition: transform 0.7s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
  }
`

var BreadCrust = styled.div`
  position: absolute;
  width: 80%;
  background: black;
  left: 10%;
  height: 12.5%;
`

var BreadCrustTop = styled(BreadCrust)`
  top: 46%;
  transform: translateY(-0.75rem);
  transition: transform 0.7s cubic-bezier(0.23, 1.25, 0.46, 1);

  ${MobileNavCheckbox}:checked
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    ${BreadTop}
    &,
  ${MobileNavCheckbox}:target
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    ${BreadTop}
    & {
    transition: transform 0.7s cubic-bezier(0.23, 1.25, 0.46, 1);
    transform: none;
  }
`

var BreadCrustBottom = styled(BreadCrust)`
  bottom: 46%;
  transform: translateY(0.75rem);
  transition: transform 0.7s cubic-bezier(0.04, 0.04, 0.12, 0.96);

  ${MobileNavCheckbox}:checked
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    ${BreadBottom}
    &,
  ${MobileNavCheckbox}:target
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    ${BreadBottom}
    & {
    transition: transform 0.7s cubic-bezier(0.04, 0.04, 0.12, 0.96);
    transform: none;
  }
`

var MenuText = styled.div`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 0.9rem;
  position: absolute;
  top: 34%;
  width: 65%;
  left: 23%;
  text-align: center;
  transform: none;
  transition: transform 0.7s cubic-bezier(0.7, 0.04, 0.05, 1);
  color: #615446;

  ${MobileNavCheckbox}:checked
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    &,
  ${MobileNavCheckbox}:target
    + ${MobileHeaderCont}
    ${HeaderNavBarComponents}
    ${BreadCont}
    & {
    transition: transform 0.7s cubic-bezier(0.23, 1.25, 0.46, 1);
    transform: translateX(5rem);
  }
`

var MobileNavLinkList = styled.div`
  margin-left: 0;
  list-style: none;
  overflow-y: auto;
  overflow: scroll;
  min-height: 25rem;
`

var MobileNavLineBreak = styled.div`
  width: auto;
  margin: 0 0.25rem;
  height: 0.1rem;
  background: black;
  opacity: 0;
  pointer-events: none;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }
`

var MNLineBreakOne = styled(MobileNavLineBreak)`
  margin: 0;
  transform: translateY(-12px);
  transition: opacity 0.25802s cubic-bezier(0.32, 0.08, 0.24, 1) 0.16s,
    transform 0.46105s cubic-bezier(0.32, 0.08, 0.24, 1) 0.12s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.31744s cubic-bezier(0.32, 0.08, 0.24, 1) 0.04s,
      transform 0.35325s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s;
  }
`

var MNLineBreakTwo = styled(MobileNavLineBreak)`
  transform: translateY(-16px);
  transition: opacity 0.24802s cubic-bezier(0.32, 0.08, 0.24, 1) 0.14s,
    transform 0.45105s cubic-bezier(0.32, 0.08, 0.24, 1) 0.1s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.32744s cubic-bezier(0.32, 0.08, 0.24, 1) 0.06s,
      transform 0.35325s cubic-bezier(0.32, 0.08, 0.24, 1) 0.05s;
  }
`
var MNLineBreakThree = styled(MobileNavLineBreak)`
  transform: translateY(-20px);
  transition: opacity 0.23802s cubic-bezier(0.32, 0.08, 0.24, 1) 0.12s,
    transform 0.44105s cubic-bezier(0.32, 0.08, 0.24, 1) 0.08s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.33744s cubic-bezier(0.32, 0.08, 0.24, 1) 0.08s,
      transform 0.37325s cubic-bezier(0.32, 0.08, 0.24, 1) 0.07s;
  }
`
var MNLineBreakFour = styled(MobileNavLineBreak)`
  transform: translateY(-24px);
  transition: opacity 0.22802s cubic-bezier(0.32, 0.08, 0.24, 1) 0.1s,
    transform 0.43105s cubic-bezier(0.32, 0.08, 0.24, 1) 0.06s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.34744s cubic-bezier(0.32, 0.08, 0.24, 1) 0.1s,
      transform 0.38325s cubic-bezier(0.32, 0.08, 0.24, 1) 0.09s;
  }
`
var MNLineBreakFive = styled(MobileNavLineBreak)`
  transform: translateY(-28px);
  transition: opacity 0.21802s cubic-bezier(0.32, 0.08, 0.24, 1) 0.08s,
    transform 0.42105s cubic-bezier(0.32, 0.08, 0.24, 1) 0.04s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.35744s cubic-bezier(0.32, 0.08, 0.24, 1) 0.12s,
      transform 0.39325s cubic-bezier(0.32, 0.08, 0.24, 1) 0.11s;
  }
`

var MobileNavLinkListItem = styled.div`
  margin-left: 0.75rem;
  margin-bottom: 0;
  display: block;
  opacity: 0;
  pointer-events: none;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transform: none;
    cursor: pointer;
    opacity: 1;
    pointer-events: auto;
  }
`

var MobileNavLinkListItemOne = styled(MobileNavLinkListItem)`
  transform: translateY(-14px);
  transition: opacity 0.25302s cubic-bezier(0.32, 0.08, 0.24, 1) 0.15s,
    transform 0.45605s cubic-bezier(0.32, 0.08, 0.24, 1) 0.11s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.32244s cubic-bezier(0.32, 0.08, 0.24, 1) 0.05s,
      transform 0.35825s cubic-bezier(0.32, 0.08, 0.24, 1) 0.04s;
  }
`

var MobileNavLinkListItemTwo = styled(MobileNavLinkListItem)`
  transform: translateY(-18px);
  transition: opacity 0.24302s cubic-bezier(0.32, 0.08, 0.24, 1) 0.13s,
    transform 0.44605s cubic-bezier(0.32, 0.08, 0.24, 1) 0.09s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.33244s cubic-bezier(0.32, 0.08, 0.24, 1) 0.07s,
      transform 0.37825s cubic-bezier(0.32, 0.08, 0.24, 1) 0.06s;
  }
`

var MobileNavLinkListItemThree = styled(MobileNavLinkListItem)`
  transform: translateY(-22px);
  transition: opacity 0.23302s cubic-bezier(0.32, 0.08, 0.24, 1) 0.11s,
    transform 0.43605s cubic-bezier(0.32, 0.08, 0.24, 1) 0.07s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.34244s cubic-bezier(0.32, 0.08, 0.24, 1) 0.09s,
      transform 0.39825s cubic-bezier(0.32, 0.08, 0.24, 1) 0.08s;
  }
`

var MobileNavLinkListItemFour = styled(MobileNavLinkListItem)`
  transform: translateY(-26px);
  transition: opacity 0.22302s cubic-bezier(0.32, 0.08, 0.24, 1) 0.09s,
    transform 0.42605s cubic-bezier(0.32, 0.08, 0.24, 1) 0.05s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.35244s cubic-bezier(0.32, 0.08, 0.24, 1) 0.11s,
      transform 0.41825s cubic-bezier(0.32, 0.08, 0.24, 1) 0.1s;
  }
`

var MobileNavLinkListItemFive = styled(MobileNavLinkListItem)`
  transform: translateY(-30px);
  transition: opacity 0.21302s cubic-bezier(0.32, 0.08, 0.24, 1) 0.07s,
    transform 0.41605s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s;

  ${MobileNavCheckbox}:checked + ${MobileHeaderCont} & {
    transition: opacity 0.32244s cubic-bezier(0.32, 0.08, 0.24, 1) 0.13s,
      transform 0.43825s cubic-bezier(0.32, 0.08, 0.24, 1) 0.12s;
  }
`

var MNLink = styled.div`
  text-decoration: none;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 700;
  color: black;
  display: block;
  width: 100%;
  padding: 1rem 0;

  ${mixins.device.mobileM} {
    font-size: 1.5rem;
    padding: 1.15rem 0;
  }
`

var MNLinkOne = styled(MNLink)``
var MNLinkTwo = styled(MNLink)``
var MNLinkThree = styled(MNLink)``
var MNLinkFour = styled(MNLink)``
var MNLinkFive = styled(MNLink)``

var HeaderPadding = styled.div`
  padding-bottom: 5.25rem;

  ${mixins.device.tablet} {
    display: none;
  }
`

function stopScroll() {
  var html = document.getElementsByTagName("html")
  var body = document.getElementsByTagName("body")

  if (document.querySelector("#mobile-nav-menustate-checkbox").checked) {
    html[0].setAttribute("style", "overflow: hidden !important; ")
    body[0].setAttribute(
      "style",
      "overflow: hidden !important; height: 100% !important;"
    )
  } else {
    html[0].setAttribute("style", "overflow: scroll; height: auto;")
    body[0].setAttribute("style", "overflow: scroll; height: auto;")
  }
}

function MobileHeader() {
  function mobileLinkClick(page) {
    let checkbox = document.querySelector("#mobile-nav-menustate-checkbox")
    checkbox.checked = false
    stopScroll()
    setTimeout(function() {
      navigate(page)
    }, 550)
  }

  function toggleMenuCheckbox() {
    var checkbox = document.querySelector("#mobile-nav-menustate-checkbox")
    if (!checkbox.checked) {
      checkbox.checked = true
      stopScroll()
    } else {
      checkbox.checked = false
      stopScroll()
    }
  }

  return (
    <>
      <MobileNavCheckboxLabel htmlFor="mobile-nav-menustate-checkbox">
        Toggle Menu
      </MobileNavCheckboxLabel>
      <MobileNavCheckbox
        type="checkbox"
        id="mobile-nav-menustate-checkbox"
        className="mobile-nav-menustate-checkbox"
        onChange={() => stopScroll()}
        onBlur={() => stopScroll()}
      />
      <MobileHeaderCont>
        <HeaderNavBarComponents>
          <LogoContainer to="/">
            <Logo style={{ width: "100%" }} />
          </LogoContainer>
          <BreadCont onClick={() => toggleMenuCheckbox()}>
            <BreadTop>
              <BreadCrustTop />
            </BreadTop>

            <MenuText>MENU</MenuText>
            <BreadBottom>
              <BreadCrustBottom />
            </BreadBottom>
          </BreadCont>
        </HeaderNavBarComponents>

        <nav
          style={{ height: "inherit", overflowY: "auto", overflow: "scroll" }}
        >
          <MobileNavLinkList>
            <MNLineBreakOne />
            <MobileNavLinkListItemOne>
              <MNLinkOne onClick={() => mobileLinkClick("/")}>Home</MNLinkOne>
            </MobileNavLinkListItemOne>
            <MNLineBreakTwo />
            <MobileNavLinkListItemTwo>
              <MNLinkTwo onClick={() => mobileLinkClick("/contact-us/")}>
                Contact Us
              </MNLinkTwo>
            </MobileNavLinkListItemTwo>
            <MNLineBreakThree />
            <MobileNavLinkListItemThree>
              <MNLinkThree onClick={() => mobileLinkClick("/case-studies/")}>
                Case Studies
              </MNLinkThree>
            </MobileNavLinkListItemThree>
            <MNLineBreakFour />
            <MobileNavLinkListItemFour>
              <MNLinkFour onClick={() => mobileLinkClick("/about-us/")}>
                About i2O
              </MNLinkFour>
            </MobileNavLinkListItemFour>
            <MNLineBreakFive />
            <MobileNavLinkListItemFive>
              <MNLinkFive onClick={() => mobileLinkClick("/faqs/")}>
                FAQs
              </MNLinkFive>
            </MobileNavLinkListItemFive>
          </MobileNavLinkList>
        </nav>
      </MobileHeaderCont>
      <HeaderPadding />
    </>
  )
}

export default MobileHeader
