import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import dsm from "../../utils/dsm"
import { device } from "../../utils/mixins"

var BronzeTxt = styled.span`
  color: ${dsm.color.brand.copper.dark};
`

export function I2BronzeText({ children }) {
  return <BronzeTxt>{children}</BronzeTxt>
}

export var SectionTitleTxt = styled.h2`
  ${dsm.fonts.primary};
  font-size: 1.75rem;
  font-weight: 750;
  text-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;

  ${device.tablet} {
    font-size: 1.85rem;
  }
`

export function SectionTitleText({ children }) {
  return <SectionTitleTxt>{children}</SectionTitleTxt>
}

var SectionTitleBelow = styled.div`
  font-size: 1.75rem;
  font-weight: 750;
  text-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.25);
  color: ${dsm.color.brand.copper.dark};
  margin-top: 0rem;
  margin-left: 1.65rem;
  margin-bottom: 0.75rem;

  ${device.mobileX} {
    margin-left: 2.65rem;
  }

  ${device.tablet} {
    font-size: 1.85rem;
    margin-left: 3.15rem;
  }
`

export function SectionTitleWrapped({ children }) {
  return <SectionTitleBelow>{children}</SectionTitleBelow>
}

var BodyTxt = styled.p`
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;

  ${device.mobileM} {
    font-size: 1.3rem;
  }

  ${device.laptop} {
    width: 85%;
  }
`

export function BodyText({ children }) {
  return <BodyTxt>{children}</BodyTxt>
}

var BodyBoldTxt = styled.span`
  font-weight: 800;
`

export function BodyBold({ children }) {
  return <BodyBoldTxt>{children}</BodyBoldTxt>
}

var BodyBoldBronzeTxt = styled.span`
  color: ${dsm.color.brand.copper.dark};
  font-weight: 800;
`

export function BodyBoldBronze({ children }) {
  return <BodyBoldBronzeTxt>{children}</BodyBoldBronzeTxt>
}

var BodyLnk = styled(Link)`
  color: ${dsm.color.brand.copper.dark};
  font-weight: 800;
  transition: color 0.35s ease-out;

  :hover {
    color: ${dsm.color.brand.copper.base};
  }
`

export function BodyLink({ children, to }) {
  return <BodyLnk to={to}>{children}</BodyLnk>
}

var PgSectionTitle = styled.h2`
  position: absolute;
  top: -2.5rem;
  padding-left: 0.5rem;
  margin: 0;
  ${dsm.fonts.primary};
  text-shadow: 0.1rem 0.1rem 0.35rem rgba(0, 0, 0, 0.25);
  font-weight: 800;
  font-size: 1.75rem;

  ${device.mobileM} {
    top: -3rem;
    font-size: 1.95rem;
  }

  ${device.tablet} {
    top: 1rem;
    background: rgba(0, 0, 0, 0.85);
    color: ${dsm.color.brand.copper.base};
    padding: 0.25rem 2.5rem 0.25rem 0.75rem;
    border-radius: 0 1.25rem 1.25rem 0;
    font-size: 2.5rem;

    span {
      color: ${dsm.color.brand.copper.base};
    }
  }
`
export function PageSectionTitle({ children }) {
  return <PgSectionTitle>{children}</PgSectionTitle>
}
