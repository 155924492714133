export default {
  color: {
    brand: {
      copper: {
        base: "#AD947A",
        dark: "#827364",
      },
    },
    text: {
      white: "#E9E9F1",
    },
  },

  fonts: {
    primary: "font-family: Montserrat;",
    secondary: "font-family: Raleway;",
  },

  h2: {
    mobileS: ``,
    mobileM: ``,
    tablet: ``,
    laptop: ``,
    laptopL: ``,
  },
}
