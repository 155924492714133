import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import Image from "gatsby-image"

import { SectionTitleText } from "../textComponents"
import dsm from "../../utils/dsm"
import { device } from "../../utils/mixins"

var FullSizeImageCont = styled.div`
  margin: 0;
  max-height: 15rem;
  position: relative;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

export function FullSizeImgCont({ children }) {
  return <FullSizeImageCont>{children}</FullSizeImageCont>
}

var FullSizePageImageCont = styled(FullSizeImageCont)`
  margin-top: 2.5rem;

  ${device.mobileM} {
    margin-top: 3rem;
  }

  ${device.tablet} {
    margin-top: 0;
  }
`

export function PageTopFullSizeImgCont({ children }) {
  return <FullSizePageImageCont>{children}</FullSizePageImageCont>
}

var SectionContentCont = styled.div`
  position: relative;
  padding 0.5rem;
  margin: 0;
  overflow: hidden;

  p:last-of-type {
    margin-bottom: 0.5rem;
  }

  ${device.mobileM} {
    padding-bottom: 1rem;
  }

  ${device.tablet} {
    padding: 1rem 0.5rem;
  }
`

export function SectionContentContainer({ children }) {
  return <SectionContentCont>{children}</SectionContentCont>
}

var SectionContentContainerNoPadding = styled(SectionContentCont)`
  margin: 0;
  padding: unset;
  padding: 0.5rem -0.25rem;
`

export function SectionContainerNoPad({ children }) {
  return (
    <SectionContentContainerNoPadding>
      {children}
    </SectionContentContainerNoPadding>
  )
}

// Section Title with Icon
var IconTitleContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 2rem;
  margin: 1rem 0.5rem;

  h2 {
    margin: 0;
    font-size: 1.85rem;

    ${device.tablet} {
      font-size: 1.95rem;
    }
  }
`

var I2IconContainer = styled.div`
  width: 9%;
  margin-right: 0.5rem;
  overflow: hidden;

  ${device.mobileX} {
    width: 6%;
    margin-right: 0.75rem;
  }

  ${device.tablet} {
    width: 4%;
  }

  ${device.laptop} {
    width: 3%;
  }
`

export function IconTitleCont({ children }) {
  var data = useStaticQuery(graphql`
    query {
      i2Icon: file(relativePath: { eq: "i2SmallIcon.png" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <IconTitleContainer>
      <I2IconContainer>
        <Image
          fluid={data.i2Icon.sharp.fluid}
          alt="i2 - The Drilling, Completions, and Production Optimization Company."
        />
      </I2IconContainer>
      <SectionTitleText>{children}</SectionTitleText>
    </IconTitleContainer>
  )
}

// Our Process Container
var OurProcessContainer = styled.div`
  margin: 1.25rem 0rem 1.5rem;
  position: relative;
  height: 19rem;

  :nth-of-type(4) {
    margin-bottom: 0.75rem;
  }

  ${device.mobileX} {
    height: 21rem;
  }

  ${device.tablet} {
    height: 16.5rem;
    margin: 0.5rem 0.5rem 1.25rem;
  }
`

var OurProcessImgContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 14rem;
  overflow: hidden;

  ${device.mobileX} {
    height: 16rem;
  }

  ${device.tablet} {
    width: 52.5%;
    left: 0;
  }
`

var OurProcessTitle = styled.h3`
  position: absolute;
  background: rgb(0, 0, 0, 0.75);
  color: white;
  top: 0.75rem;
  ${dsm.fonts.primary}
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0.35rem 0.5rem;
  width: 12rem;
  border-radius: 0 1.5rem 1.5rem 0;
  color: ${dsm.color.brand.copper.base};
  text-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.25);

  ${device.mobileM} {
    width: 12.5rem;
    font-size: 1.6rem;
  }

  ${device.mobileM} {
    width: 13rem;
    font-size: 1.7rem;
  }

  ${device.mobileX} {
    font-size: 1.75rem;
    width: 13.5rem;
  }

  ${device.tablet} {
    width: 15rem;
    right: 0;
    border-radius: 0.25rem;
    background: rgba(0, 0, 0, 1);
    top: 1.25rem;
    padding: 0.75rem;
    width: 49%;
    padding-top: 1rem;
    padding-left: 1.25rem;
    z-index: 9;
  }

  ${device.laptop} {
    padding-top: 1.25rem;
    padding-left: 1.5rem;
    width: 48%;
  }
`

var OurProcessDesc = styled.div`
  position: absolute;
  bottom: 0;
  background: black;
  color: ${dsm.color.text.white};
  padding: 0.5rem;
  font-size: 1.15rem;
  font-weight: 600;

  ${device.mobileM} {
    font-size: 1.16rem;
  }

  ${device.mobileX} {
    font-size: 1.25rem;
  }

  ${device.tablet} {
    bottom: 1.5rem;
    width: 49%;
    right: 0;
    height: 10.5rem;
    display: flex;
    align-items: center;

    border-radius: 0.25rem;
    padding: 0.75rem;
    text-align: left;
    padding-left: 2.5%;
  }

  ${device.laptop} {
    width: 48%;
    padding-right: 5rem;
  }
`

export function OurProcessCont({ children, title, desc }) {
  return (
    <OurProcessContainer>
      <OurProcessImgContainer>{children}</OurProcessImgContainer>
      <OurProcessTitle>{title}</OurProcessTitle>
      <OurProcessDesc>{desc}</OurProcessDesc>
    </OurProcessContainer>
  )
}

var OurProcessContainerRight = styled(OurProcessContainer)``
var OurProcessImgContainerRight = styled(OurProcessImgContainer)`
  ${device.tablet} {
    left: unset;
    right: 0;
  }

  ${device.laptop} {
    left: unset;
    right: 0;
  }
`
var OurProcessTitleRight = styled(OurProcessTitle)`
  ${device.tablet} {
    left: 0;
    border-radius: 0.25rem;
  }

  ${device.laptop} {
    left: 0;
  }
`
var OurProcessDescRight = styled(OurProcessDesc)`
  ${device.tablet} {
    left: 0;
    border-radius: 0.25rem;
  }
`

export function OurProcessContRight({ children, title, desc }) {
  return (
    <OurProcessContainerRight>
      <OurProcessImgContainerRight>{children}</OurProcessImgContainerRight>
      <OurProcessTitleRight>{title}</OurProcessTitleRight>
      <OurProcessDescRight>{desc}</OurProcessDescRight>
    </OurProcessContainerRight>
  )
}

// INDEX - Where We're Optimizing Cont
var WhereContainer = styled.div`
  display: flex;
  height: 5rem;
  margin: 1rem 0 1.5rem;

  ${device.mobileL} {
    height: 6rem;
  }

  ${device.tablet} {
    width: 70%;
    height: 6rem;
  }

  ${device.laptop} {
    width: 49%;
  }
`

var WhereImgCont = styled.div`
  width: 40%;
  height: 100%;
`

var WhereDetailsCont = styled.div`
  margin: 0.15rem 0.45rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${device.mobileL} {
    margin: 0.35rem 0.5rem;
  }

  ${device.mobileL} {
    margin: 0.35rem 1rem;
  }
`

var WhereLocation = styled.h4`
  ${dsm.fonts.primary}
  margin: 0;
  font-weight: 800;
  font-size: 1.25rem;
  color: ${dsm.color.brand.copper.dark};

  ${device.mobileM} {
    font-size: 1.35rem;
  }

  ${device.mobileL} {
    font-size: 1.4rem;
  }
`

var WhereGeoLoc = styled.div`
  margin: 0;
  font-size: 1.15rem;
  font-weight: 750;

  ${device.mobileM} {
    font-size: 1.25rem;
  }

  ${device.mobileL} {
    font-size: 1.3rem;
  }
`

var WhereCategory = styled.div`
  ${dsm.fonts.primary}
  font-size: 1.1rem;
  font-weight: 600;
  color: ${dsm.color.brand.copper.dark};

  ${device.mobileM} {
    font-size: 1.2rem;
  }

  ${device.mobileL} {
    font-size: 1.25rem;
  }
`

export function WhereCont({ children, location, geo, category }) {
  return (
    <WhereContainer>
      <WhereImgCont>{children}</WhereImgCont>
      <WhereDetailsCont>
        <WhereLocation>{location}</WhereLocation>
        <WhereGeoLoc>{geo}</WhereGeoLoc>
        <WhereCategory>{category}</WhereCategory>
      </WhereDetailsCont>
    </WhereContainer>
  )
}

// ABOUT US IMAGE COMPONENTS

// Persona

var PersonaCont = styled.div`
  height: 11rem;
  display: flex;
  margin-bottom: 1rem;
`

var PersonaImgCont = styled.div`
  height: 11rem;
  width: 42%;

  ${device.mobileL} {
    width: 48%;
  }

  ${device.mobileX} {
    width: 52%;
  }

  ${device.tablet} {
    width: 49%;
  }

  ${device.laptop} {
    width: 33%;
  }
`

var PersonaDetails = styled.div`
  width: 55%;
  margin-left: 3%;
  display: grid;
  grid-direction: column;
  align-content: space-evenly;

  ${device.mobileL} {
    width: 49%;
  }

  ${device.mobileX} {
    width: 45%;
    margin-bottom: 0.5rem;
  }

  ${device.tablet} {
    width: 35%;
  }

  ${device.laptop} {
    margin-left: 1.5%;
  }
`

var PersonaName = styled.h3`
  margin: 0;
  ${dsm.fonts.primary};
  font-size: 1.45rem;

  ${device.mobileM} {
    font-size: 1.55rem;
  }

  ${device.mobileX} {
    font-size: 1.65rem;
  }
`

var PersonaTitle = styled.div`
  margin: 0;
  font-weight: 800;
  font-size: 1.25rem;
  color: ${dsm.color.brand.copper.dark};

  ${device.mobileX} {
    font-size: 1.4rem;
    margin-bottom: 0.75rem;
  }
`

var PersonaQuote = styled.div`
  font-size: 1.15rem;
  font-weight: 550;
  font-style: italic;

  ${device.mobileX} {
    font-size: 1.2rem;
  }
`

export function PersonaContainer({ children, name, title, quote }) {
  return (
    <PersonaCont>
      <PersonaImgCont>{children}</PersonaImgCont>
      <PersonaDetails>
        <PersonaName>{name}</PersonaName>
        <PersonaTitle>{title}</PersonaTitle>
        <PersonaQuote>{quote}</PersonaQuote>
      </PersonaDetails>
    </PersonaCont>
  )
}
