import React from "react"
export default function JDSFullscreenCollapse() {
  return (
    <svg viewBox="0 0 231 52" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M.006 49.974c0 .268.227 1.103 1.13 1.104 3.423.003 11.268.01 13.679.01.403 0 .797-.01 1.184-.03 7.63-.392 12.261-5.107 12.261-13.376 0-7.793.01-11.927.011-19.186 0-.402-.427-1.088-1.095-1.088-3.28-.001-6.265-.006-8.865-.007-.416 0-1.106.448-1.105 1.159.003 6.887.03 17.492.03 18.721 0 2.629-1.674 2.826-3.52 2.826l-12.817.002c-.476 0-.878.703-.878.93-.036.957-.015 7.275-.015 8.935z"
          fill="#000"
        />
        <path
          d="M34.55 1.01c0-.206.242-.918.995-.918 4.157.001 14.846.013 15.182.03l1.045.093.036.004c6.233.676 10.245 4.452 11.023 10.853l-.014 26.61c0 7.756-4.074 12.385-10.874 13.254l-1.388.123c-.118.006-11.027.017-15.105.022-.793 0-.938-.801-.938-1.063v-8.875c0-.255.113-1.04 1.033-1.04h6.01v-.001c1.223 0 6.352.007 6.352.007 2.72 0 3.749-.61 3.886-2.639l-.019-23.98c0-1.895-1.244-2.42-3.663-2.42H35.545c-.831 0-.975-.721-.995-.923V1.01z"
          fill="#073DA6"
        />
        <path
          d="M28.197 1.146C28.197.513 27.684 0 27.052 0h-8.744c-.632 0-1.145.513-1.145 1.146v8.72c0 .633.513 1.146 1.145 1.146h8.744c.632 0 1.145-.513 1.145-1.145V1.146z"
          fill="#000"
        />
        <g>
          <path
            d="M89.927 6.924h5.854v3.791c0 3.172-2.122 5.032-6.195 5.032-2.269 0-4.073-.573-5.39-1.216L82 18.894c1.464.811 4.025 1.741 7.83 1.741 7.122 0 11.805-3.29 11.805-9.324V2.155H89.927v4.769zM125.89 7.082V2.155h-19.635v18.48h19.612v-4.928h-13.879v-2.094h11.083V9.177h-11.083V7.082zM152.455 2.114h-5.75v9.232L130.51 1v19.676h5.75v-9.231l16.195 10.345zM157.075 18.131c2.53 1.179 6.228 2.504 11.91 2.504 6.525 0 11.19-2.11 11.19-6.43 0-4.64-5.756-5.646-13.2-6.063-2.084-.123-3.25-.294-3.25-.982 0-.957 2.307-1.252 4.764-1.252 3.225 0 6.277.639 8.336 1.473l1.96-4.491c-2.53-1.08-6.029-1.89-9.924-1.89-5.93 0-11.09 1.742-11.09 6.455 0 4.025 3.944 5.449 12.48 5.989 2.307.147 3.969.27 3.969 1.03 0 .958-2.63 1.252-4.938 1.252-4.292 0-7.617-.957-10.048-2.086l-2.159 4.491zM203.275 7.082V2.155H183.64v18.48h19.61v-4.928h-13.877v-2.094h11.083V9.177h-11.083V7.082zM229.84 2.114h-5.75v9.232L207.896 1v19.676h5.75v-9.231L229.84 21.79z"
            fill="#073DA6"
          />
          <path
            d="M91.875 45.338a39.29 39.29 0 01-2.51-.078v-9.98a39.29 39.29 0 012.51-.078c6.159 0 10.221 1.56 10.221 5.068 0 3.508-4.062 5.068-10.221 5.068zm-8.72 4.937c2.925.234 5.59.39 7.944.39 12.42 0 17.466-4.47 17.466-10.395s-5.046-10.395-17.466-10.395c-2.355 0-5.02.156-7.944.39v20.01zM133.975 35.111v-5.236H112.03V49.51h21.918v-5.236h-15.51v-2.225h12.387v-4.713h-12.388v-2.225zM137.44 48.014c2.658 1.248 6.54 2.651 12.505 2.651 6.852 0 11.75-2.235 11.75-6.809 0-4.911-6.044-5.977-13.86-6.419-2.188-.13-3.413-.311-3.413-1.04 0-1.013 2.423-1.325 5.002-1.325 3.387 0 6.592.676 8.754 1.56l2.058-4.756c-2.657-1.143-6.331-2.001-10.421-2.001-6.227 0-11.645 1.845-11.645 6.835 0 4.262 4.142 5.769 13.104 6.34 2.423.157 4.168.286 4.168 1.092 0 1.014-2.761 1.325-5.184 1.325-4.507 0-7.998-1.013-10.552-2.208l-2.266 4.755zM166.315 49.51h5.775V29.875h-5.775zM197.352 39.932v3.95c-1.344.78-3.45 1.456-6.003 1.456-4.37 0-8.057-1.924-8.057-5.068 0-3.17 3.686-5.068 8.057-5.068 2.29 0 4.58.52 6.345 1.326l2.422-4.756c-2.396-1.195-5.397-1.897-8.767-1.897-8.715 0-14.639 4.47-14.639 10.395s5.924 10.395 14.639 10.395c5.16 0 9.372-1.715 11.926-4.106v-6.627h-5.923zM230.995 29.896h-6.355v9.744l-17.9-10.92v20.77h6.355v-9.745l17.9 10.92z"
            fill="#000"
          />
        </g>
      </g>
    </svg>
  )
}
