import "typeface-montserrat"
import "typeface-raleway"
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { device } from "../utils/mixins"

import "./layout.css"
import MobileHeader from "./header/mobileHeader"
import FullscreenHeader from "./header/fullscreenHeader"
import Footer from "./footer"

var BodyContent = styled.div`
  width: 100%;

  ${device.laptopL} {
    max-width: 1200px;
    margin: 0 calc((100vw - 1200px) / 2);
  }
`

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <MobileHeader siteTitle={data.site.siteMetadata.title} />
      <FullscreenHeader siteTitle={data.site.siteMetadata.title} />
      <BodyContent>
        <main>{props.children}</main>
        <Footer />
      </BodyContent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
