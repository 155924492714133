import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import throttle from "lodash.throttle"

import * as mixins from "../../utils/mixins"
import dsm from "../../utils/dsm"
import I2HeaderBackground from "../../images/i2HeaderBackground.svg"

import I2Logo from "./I2Logo"

var FSHeaderCont = styled.header`
  display: none;

  ${mixins.device.tablet} {
    display: ${props => (props.collapse ? "flex" : "block")};
  }

  position: fixed;
  background: url(${I2HeaderBackground});
  background-size: cover;

  z-index: 999;
  width: 100%;
  max-width: 1200px;
  height: 8rem;
  top: ${props => (props.collapse ? "-4.5rem" : "0")};
  transition: top 0.7s ease-in-out;

  overflow-y: hidden;

  ${mixins.device.laptopL} {
    margin: 0 calc((100vw - 1200px) / 2);
  }
`

var LogoAndTagCont = styled.div`
  display: flex;
  height: ${props => (props.collapse ? "55%" : "70%")};
  width: ${props => (props.collapse ? "25%" : "initial")};
  align-self: ${props => (props.collapse ? "flex-end" : "center")};
  transition: all 0.7s ease-in-out;
`

var LogoContainer = styled(Link)`
  display: flex;
  height: 95%;
  width: ${props => (props.collapse ? "100%" : "45%")};
  padding-left: 0.5rem;
  padding-top: ${props => (props.collapse ? "1.2rem" : "0.2rem")};
  z-index: 9;
`

var Logo = styled(I2Logo)`
  width: 100%;
  height: ${props => (props.collapse ? "100%" : "5.5rem")};
`

var Tagline = styled.div`
  width: ${props => (props.collapse ? "0" : "55%")};
  opacity: ${props => (props.collapse ? "0" : "1")};
  padding-right: 0.5rem;

  display: ${props => (props.collapse ? "none" : "flex")};
  align-items: center;
  justify-content: flex-end;

  color: black;
  ${dsm.fonts.primary};
  font-weight: 800;
  font-size: 1.25rem;

  ${mixins.device.laptop} {
    width: 65%;
    font-size: 1.4rem;
  }
`

var TaglineCopper = styled.span`
  color: ${dsm.color.brand.copper.dark};
`

var FSHeaderLinkCont = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.7rem;
  padding-left: ${props => (props.collapse ? "1.75rem" : "0.7rem")};
  transition: all 0.7s ease-in-out;
  width: ${props => (props.collapse ? "75%" : "initial")};
  height: ${props => (props.collapse ? "45%" : "30%")};
`

var FSHeaderLink = styled(Link)`
  color: black;
  ${dsm.fonts.primary};
  text-decoration: none;
  font-weight: 750;
  font-size: 0.9rem;

  :hover {
    color: ${dsm.color.brand.copper.dark};
  }

  ${mixins.device.laptop} {
    font-size: 1rem;
  }
`

var FSHeaderPadding = styled.div`
  padding-bottom: 8rem;
  display: none;

  ${mixins.device.tablet} {
    display: block;
  }
`

class FullscreenHeader extends React.Component {
  state = {
    collapse: null,
  }

  checkScroll = throttle(() => {
    var scrollPos = Math.round(window.scrollY)

    if (scrollPos > 20) {
      this.setState({ collapse: 1 })
    } else if (scrollPos < 20) {
      this.setState({ collapse: 0 })
    }
  }, 150)

  componentDidMount() {
    window.addEventListener("scroll", this.checkScroll)
  }

  componentWillUnmount() {
    this.isComponentMounted = false
    window.removeEventListener("scroll", this.checkScroll)
  }

  render() {
    return (
      <>
        <FSHeaderCont collapse={this.state.collapse}>
          <LogoAndTagCont collapse={this.state.collapse}>
            <LogoContainer collapse={this.state.collapse} to="/">
              <Logo collapse={this.state.collapse} />
            </LogoContainer>
            <Tagline collapse={this.state.collapse}>
              <TaglineCopper>Global Ideas.&nbsp;</TaglineCopper>
              Local Solutions.
            </Tagline>
          </LogoAndTagCont>
          <FSHeaderLinkCont collapse={this.state.collapse}>
            <FSHeaderLink
              to="/"
              activeStyle={{
                color: `${dsm.color.brand.copper.dark}`,
                textDecoration: "underline",
              }}
            >
              HOME
            </FSHeaderLink>
            <FSHeaderLink
              to="/contact-us/"
              activeStyle={{
                color: `${dsm.color.brand.copper.dark}`,
                textDecoration: "underline",
              }}
            >
              CONTACT US
            </FSHeaderLink>
            <FSHeaderLink
              to="/case-studies/"
              activeStyle={{
                color: `${dsm.color.brand.copper.dark}`,
                textDecoration: "underline",
              }}
            >
              CASE STUDIES
            </FSHeaderLink>
            <FSHeaderLink
              to="/about-us/"
              activeStyle={{
                color: `${dsm.color.brand.copper.dark}`,
                textDecoration: "underline",
              }}
            >
              ABOUT i2O
            </FSHeaderLink>
            <FSHeaderLink
              to="/faqs/"
              activeStyle={{
                color: `${dsm.color.brand.copper.dark}`,
                textDecoration: "underline",
              }}
            >
              FAQs
            </FSHeaderLink>
          </FSHeaderLinkCont>
        </FSHeaderCont>
        <FSHeaderPadding />
      </>
    )
  }
}

export default FullscreenHeader
