import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, structuredData }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            siteImg
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  var schemaOrgWebPage = {
    "@context": "http://schema.org/",
    "@type": "WebPage",
    url: site.siteMetadata.siteUrl,
    inLanguage: lang,
    mainEntityOfPage: site.siteMetadata.siteUrl,
    description: description,
    name: title,
    author: {
      "@type": "Person",
      name: "Matt Deady",
    },
    copyrightHolder: {
      "@type": "Person",
      name: "Matt Deady",
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: "Mark Jensen",
    },
    publisher: {
      "@type": "Person",
      name: "Mark Jensen",
    },
    datePublished: "2019-01-18T10:30:00+01:00",
    dateModified: "2019-08-18T10:30:00+01:00",
    image: {
      "@type": "ImageObject",
      url: `https://res.cloudinary.com/dxmbnxuho/image/upload/v1571344248/i2_Optimization_Oil_and_Gas_Drilling_Production_Completions-tw.jpg`,
    },
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `google-site-verification`,
          content: `hNmipgHY5VpFSFGYgYsHUYVAp-dNx5FNFZFJWizKYLk`,
        },
        {
          name: `image`,
          content: `${site.siteMetadata.siteImg}`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}`,
        },
        {
          property: `og:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
