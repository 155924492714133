var size = {
  mobileM: "360px",
  mobileL: "425px",
  mobileX: "565px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1200px",
}

export var device = {
  mobileM: `@media (min-width: ${size.mobileM})`,
  mobileL: `@media (min-width: ${size.mobileL})`,
  mobileX: `@media (min-width: ${size.mobileX})`,
  tablet: `@media (min-width: ${size.tablet})`,
  laptop: `@media (min-width: ${size.laptop})`,
  laptopL: `@media (min-width: ${size.laptopL})`,
}

export var visuallyHidden = `
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`
