import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import I2HeaderBackground from "../images/i2HeaderBackground.svg"
import dsm from "../utils/dsm"
import { device } from "../utils/mixins"
import JDSLogo from "../components/imageComponents/JDSLogo"

var FooterCont = styled.footer`
  background: url(${I2HeaderBackground});
  background-size: contain;
  display: flex;
  flex-wrap: wrap;
  height: 9rem;
  align-content: space-evenly;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
  padding-top: 2rem;

  ${device.mobileX} {
    height: 11rem;
    padding-top: 1rem;
  }

  ${device.tablet} {
    background-size: cover;
    align-items: center;
  }

  ${device.laptop} {
    margin-top: 1rem;
  }
`

var FtCompanyName = styled.div`
  ${dsm.fonts.primary};
  text-align: center;
  font-weight: 800;
  text-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  color: ${dsm.color.brand.copper.dark};
  font-size: 1.15rem;
  width: 100%;

  ${device.tablet} {
    width: 40%;
    text-align: left;
    padding-left: 1.5rem;
  }
`

var FtLinkCont = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 0.75rem;
  width: 100%;

  ${device.tablet} {
    margin-top: 0;
    width: 60%;
  }
`

var FtLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 1rem;
  font-weight: 750;

  ${device.mobileM} {
    font-size: 1.15rem;
  }
`

var JDSBranding = styled.div`
  width: 100%;
  padding: 0 1%;
  margin: 1.5rem 0 1rem;
  font-weight: bold;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  ${device.mobileM} {
    font-size: 1rem;
    margin: 1.65rem 0 1rem;
    justify-content: space-evenly;
  }

  ${device.mobileL} {
    width: 85%;
    margin: 1.75rem 7.5% 1rem;
  }

  ${device.mobileX} {
    width: 25rem;
    margin: 2rem auto 1rem;
  }
`

var BrandingDesc = styled.div``

var BrandingLink = styled.a`
  width: 42.5%;
  display: block;

  ${device.mobileM} {
    width: 8.5rem;
  }

  ${device.mobileL} {
    width: 9rem;
  }
`

function Footer() {
  return (
    <FooterCont>
      <FtCompanyName>
        © {new Date().getFullYear()} i2 Optimization
      </FtCompanyName>
      <FtLinkCont>
        <FtLink to="/privacy-policy/">Privacy</FtLink>
        <FtLink to="/contact-us/">Contact</FtLink>
        <FtLink to="/about-us/">About</FtLink>
      </FtLinkCont>

      <JDSBranding>
        <BrandingDesc>Website Design by</BrandingDesc>
        <BrandingLink
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.jensendesignshop.com"
        >
          <JDSLogo />
        </BrandingLink>
      </JDSBranding>
    </FooterCont>
  )
}

export default Footer
